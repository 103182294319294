import { get, post } from "@/network/request.js";

//登录
export const wechatLogin = (p) => post("account/wechatPageLogin", p);
//更新个人信息
export const updateUser = (p) => post("profile/member", p);
//个人信息
export const userDetail = (p) => get("profile/detail", {});
//活动介绍
export const eventInfo = (p) => get("general/subject", {});
//分类列表
export const getCategoryList = (p) => get(`votes/category?year=${p}`, {});
//自分类
export const getSubCategoryList = (p) => get(`votes/category/${p}`, {});
//作品分类列表
export const getWorksCategoryList = (p) => get("votes/simpleCategory", {});
//作品列表
export const getWorksList = (p) => get("votes/index", p);
//作品详情
export const worksDetail = (p) => get(`votes/index/${p}`, {});
//投票
export const doVote = (p) => post("votes/doVote", {});
//投票记录
export const voteRecoredList = (p) => get("profile/voteRecord", {});
export const homeDate = (p) => get("general/carousel", {});
export const rankingList = (p) => get("votes/top", {});

//文章列表
export const fetchArticleList = (p) => get("general/article", {});
//文章详情
export const fetchArticleDetail = (p) => get("general/article/" + p, {});
