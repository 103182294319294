let baseUrl = "";
switch (process.env.NODE_ENV) {
  case "development":
    baseUrl = "https://api.hbzhengwu.com/heroine/api/";
    break;
  case "production":
    baseUrl = "https://api.hbzhengwu.com/heroine/api/";
    break;
}

export default baseUrl;
