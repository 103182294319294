import emitter from "@/utils/mitt.js";
import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

// const tabbarStore = useTabbarStore();

const routes = [
  {
    path: "/home",
    redirect: "/",
    meta: {
      isRoot: true,
      keepAlive: true,
    },
  },
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      isRoot: true,
      keepAlive: true,
    },
  },
  // {
  //   path: "/:pathMatch(.*)*",
  //   redirect: "/",
  // },
  {
    path: "/my",
    name: "my",
    component: () => import("../views/My.vue"),
    meta: {
      isRoot: true,
      keepAlive: true,
    },
  },
  {
    path: "/activity",
    name: "Activity",
    component: () => import("../views/Activity.vue"),
  },
  {
    path: "/intro",
    name: "intro",
    component: () => import("../views/Intro.vue"),
    meta: {
      isRoot: true,
      keepAlive: true,
    },
  },
  {
    path: "/show",
    name: "show",
    component: () => import("../views/Show.vue"),
    meta: {
      isRoot: true,
      keepAlive: true,
    },
  },
  {
    path: "/workdetail/:id",
    name: "WorkDetail",
    component: () => import("../views/WorkDetail.vue"),
  },
  {
    path: "/ranklist",
    name: "RankList",
    component: () => import("../views/RankList.vue"),
  },

  {
    path: "/newslist",
    name: "NewsList",
    component: () => import("../views/NewsList.vue"),
  },
  {
    path: "/newsdetail/:id",
    name: "NewsDetail",
    component: () => import("../views/NewsDetail.vue"),
  },
  {
    path: "/favlist",
    name: "FavouriteList",
    component: () => import("../views/FavouriteList.vue"),
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.isRoot) {
    // tabbarStore.updateCurItem(to.meta.name);
    emitter.emit("sel_item", to.name);
    window.localStorage.setItem("tabbar_name", to.name);
  }
  next();
});

export default router;
