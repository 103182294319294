<template>
  <router-view v-if="$route.meta.keepAlive" v-slot="{ Component }">
    <keep-alive>
      <component :is="Component"></component>
    </keep-alive>
  </router-view>
  <router-view v-if="!$route.meta.keepAlive" v-slot="{ Component }">
    <component :is="Component"></component>
  </router-view>

  <keep-alive>
    <TabBar :curIndex="$route.meta.name" v-if="$route.meta.isRoot"> </TabBar>
  </keep-alive>
</template>

<script setup>
import TabBar from "@/components/TabBar.vue";
const components = {
  TabBar,
};
</script>
