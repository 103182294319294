import { defineStore } from "pinia";

// 第一个参数是应用程序中 store 的唯一 id
export const useTabbarStore = defineStore("tabbar", {
  // state 是一个函数，返回一个包含所有状态的对象
  state: () => ({
    curItem: "",
    showHomeAlert: true,
  }),
  // getters 相当于计算属性
  getters: {
    localItem: (state) => window.localStorage.getItem("tabbar_name") ?? "home",
    homeAlert: (state) => state.showHomeAlert,
  },
  // actions 可以包含异步操作，用于修改 state
  actions: {
    updateCurItem(name) {
      this.curItem = name;
      window.localStorage.setItem("tabbar_name", name);
    },
    updateHomeAlert() {
      this.showHomeAlert = false;
    },
  },
});
